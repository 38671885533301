import { useBreakpoint } from "@hoken/core";

import { Link } from "../../application";
import { WelcomeHeroText } from "./welcome-hero-text/welcome-hero-text";
import { WelcomeHeroWrapper } from "./welcome-hero.css";

import { Autolayout, Text } from "@hoken/design-system";

export const WelcomeHero = (): JSX.Element => {
  const isDesktop = useBreakpoint("isDesktop");
  return (
    <section className={WelcomeHeroWrapper}>
      {isDesktop && (
        <Autolayout
          justify='end'
          direction='row'
          style={{ maxWidth: "calc(1368px - 8.5rem)", width: "calc(100vw - 8.5rem)" }}
        >
          <Text
            style={{
              color: "rgba(255, 255, 255, 0.6)",
              marginBottom: "-1.5rem",
            }}
            type='P3'
          >
            <Link href='tel:8312085498'>Need Help Booking?: 831-208-5498</Link>
          </Text>
        </Autolayout>
      )}
      <WelcomeHeroText />
    </section>
  );
};
