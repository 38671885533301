import { varsHoken } from "@hoken/design-system";
import { style } from "@vanilla-extract/css";

export const featuredOnWrapper = style({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "197px",
  justifyContent: "center",
  marginLeft: "calc((100vw - 100%)/-2)",
  width: "100vw",
  padding: "2rem 2rem",

  "@media": {
    "screen and (min-width: 1024px)": {
      padding: `${varsHoken.space["xxlarge"]} 0`,
      margin: "0",
      width: "auto",
    },
  },
});

export const featuredOnLogosContainer = style({
  overflow: "hidden",
  width: "100vw",
  position: "relative",
});

export const featuredOnLogosWrapper = style({
  display: "flex",
  gap: "10vw",
  padding: `${varsHoken.space["xlarge"]} ${varsHoken.space["medium"]} ${varsHoken.space["large"]}`,
  textAlign: "center",
  justifyContent: "center",
  width: "100%",
  marginTop: "0",
  flexWrap: "wrap",

  "@media": {
    "screen and (min-width: 768px)": {
      padding: `${varsHoken.space["xlarge"]} ${varsHoken.space["medium"]} 0`,
      gap: "5rem",
    },
  },
});
export const featuredOnLogo = style({
  flex: "0 0 auto",
  margin: 0,
  width: "20vw",
  display: "flex",
  justifyContent: "center",
  "@media": {
    "screen and (min-width: 768px)": {
      width: "140.8px",
      marginBottom: varsHoken.space["xlarge"],
      display: "flex",
      alignItems: "center",
    },
  },
});
