import { style } from "@vanilla-extract/css";
import { recipe } from "@vanilla-extract/recipes";

export const TrustPilotWrapper = recipe({
  base: {},
  variants: {
    size: {
      small: {
        width: "11rem",
        height: "calc(11rem * .51)",
      },
      medium: {
        width: "11rem",
        height: "calc(11rem * .55)",
      },
      large: {
        width: "20rem",
        height: "calc(20rem * .51)",
      },
    },
    type: {
      horizontal: {},
      micro: {},
      mini: {},
      grid: {},
    },
  },
  compoundVariants: [
    {
      variants: {
        size: "medium",
        type: "horizontal",
      },
      style: {
        width: "14rem",
        height: "calc(2rem)",
      },
    },
    {
      variants: {
        size: "medium",
        type: "micro",
      },
      style: {
        width: "15rem",
        height: "calc(1.5rem)",
      },
    },
    {
      variants: {
        size: "small",
        type: "horizontal",
      },
      style: {
        width: "10rem",
        height: "1.5rem",
      },
    },
  ],
  defaultVariants: {
    size: "medium",
  },
});

export const trustPilotContainer = style({
  position: "relative",
});

export const trustPilotClickTrap = style({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
});
