// Dependencies
import { useBreakpoint } from "@hoken/core";

import { howItWorksItems } from "./how-it-works-items";
import {
  gradientTextWrapper,
  howItWorksBenefitIconWrapper,
  howItWorksBenefitTitle,
  howItWorksBenefits,
  howItWorksDiv,
  howItWorksWrapper,
} from "./how-it-works.vanilla.css";

import { Icons, Text } from "@hoken/design-system";

const SVGIcon = ({ componentName }: { componentName: string }) => {
  switch (componentName) {
    case "EndlessScrolling":
      return <Icons.EndlessScrolling />;
    case "VipAccess":
      return <Icons.VipAccess />;
    case "EpicSavings":
      return <Icons.EpicSavings />;
    case "ChangeOfPlans":
      return <Icons.ChangeOfPlans />;
    case "HokenLocationsAndSavings":
      return <Icons.HokenLocationsAndSavings />;
    case "HokenExpertTeam":
      return <Icons.HokenExpertTeam />;
    default:
      return <></>;
  }
};

export const HowItWorks = (): JSX.Element => {
  const isDesktop = useBreakpoint("isDesktop");
  return (
    <section className={howItWorksWrapper}>
      <div className={gradientTextWrapper}>
        <Text type='H1' as='h2' font='helvetica' colors='white' alignment='center'>
          Skip the hassle, find your ideal event hotel
        </Text>
      </div>
      {/* Begin::Different benefits of use Hoken items */}
      <div className={howItWorksBenefits}>
        {howItWorksItems.map((item) => {
          return (
            <div key={item.title} className={howItWorksDiv}>
              <div className={howItWorksBenefitIconWrapper}>
                <SVGIcon componentName={item.icon} />
              </div>
              <Text
                colors='white'
                as='h2'
                type={isDesktop ? "H3" : "H4"}
                dangerouslySetInnerHTML={{ __html: item.title }}
                className={howItWorksBenefitTitle}
              />
              <div className={item.className}>
                <Text
                  as='div'
                  type='P2'
                  colors='white'
                  dangerouslySetInnerHTML={{ __html: item.desc }}
                />
              </div>
            </div>
          );
        })}
      </div>
      {/* Ends::Different benefits of use Hoken items */}
    </section>
  );
};
