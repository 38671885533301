// Interfaces
import { FeaturedOnLogoProps } from "./featured-on.interfaces";

export const featuredOnLogos: FeaturedOnLogoProps[] = [
  {
    imgName: "logo-marriot",
    imgAlt: "Marriot Logo ",
  },
  {
    imgName: "logo-hilton",
    imgAlt: "Hilton Logo",
  },
  {
    imgName: "logo-hyatt",
    imgAlt: "Hyatt Logo",
  },
  {
    imgName: "logo-ihg",
    imgAlt: "IHG Logo",
  },
  {
    imgName: "logo-choice",
    imgAlt: "Choice Logo",
  },
];
