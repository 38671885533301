import {
  DropsTicker,
  ENDPOINT_DROPS,
  ENDPOINT_FEATURED_DROPS,
  ErrorAlert,
  FooterDesktopWrapper,
  FooterMobileWrapper,
  HeaderDesktop,
  HeaderDesktopStickyWrapper,
  HomeWrapper,
  Layout,
  MobileNav,
  fetcher,
  serverResolveCanonical,
  useBreakpoint,
} from "@hoken/core";

import { getAnonymousToken, nextAuthOptions } from "./api/auth/[...nextauth]";

import { unstable_getServerSession } from "next-auth/next";
import { unstable_serialize } from "swr";

type NewSession = {
  user: Record<string, never>;
  expires: string;
  type?: "anonymous" | "user";
  token?: string;
};

type Fallback = {
  [key: string]: unknown;
};

// @ts-expect-error next.js known type issue with context
export async function getServerSideProps(context) {
  const canonical = serverResolveCanonical(context);
  const responseData: {
    props: {
      session?: NewSession;
      fallback?: Fallback;
      canonical?: string;
    };
  } = {
    props: {
      canonical,
    },
  };

  // Retrieve the current next auth session object locally, avoids additional network request
  const authOptions = nextAuthOptions(context.req, context.res);
  const session = await unstable_getServerSession(context.req, context.res, authOptions);
  const sessionSanitized = JSON.parse(JSON.stringify(session));
  const hasToken = sessionSanitized?.token;
  const userType = sessionSanitized?.type;

  const missingAuthentication = !hasToken && !userType;
  const hasAuthentication = hasToken && userType;

  /**
   * When a session is not available we create and return a session object from getServerSideProps.
   * Typically next auth manages the session but the library does not support SSR session creation.
   * This scenario happens when on the first ever request to the application. Subsequent request to the server retrieve the existing session.
   */

  // TODO: This populated _app -> props -> session and useSession on init render but useSession becomes undefined shortly after causing a flicker
  if (missingAuthentication) {
    const expiresImmediately = new Date().toISOString();
    const newSession: NewSession = {
      user: {},
      expires: expiresImmediately,
    };

    const anonymousToken = await getAnonymousToken();
    newSession.type = "anonymous";
    newSession.token = anonymousToken?.token;
    responseData.props.session = newSession;
  }

  // When getServerSession returns a session object we return the existing session
  if (hasAuthentication) {
    responseData.props.session = sessionSanitized;
  }

  const hasValidSession =
    responseData.props?.session?.token && responseData.props?.session?.type;

  // Fetch all data required for init load and populate the useSWR cache
  // TODO: Remove token from request and only use endpoints, The cache is simpler to manage without having to track/serialize the token.
  if (hasValidSession) {
    const token = responseData.props?.session?.token;
    const requestDrops = { endpoint: ENDPOINT_DROPS, token: token };
    const requestFeaturedDrops = { endpoint: ENDPOINT_FEATURED_DROPS, token: token };
    // TODO: Refactor into function for re-use in refresh token scenario
    try {
      // TODO: Refactor to promise.all as any rejection is likely due to refresh token request.
      const dropsData = await fetcher(requestDrops);
      const featuredDropsData = await fetcher(requestFeaturedDrops);
      responseData.props.fallback = {
        [unstable_serialize(requestDrops)]: dropsData,
        [unstable_serialize(requestFeaturedDrops)]: featuredDropsData,
      };
    } catch (error) {
      // TODO: init refresh token flow then re-request
      // Clear fallback cache from invalid properties, this treats refresh flow as a ssr no-opt and boots client loading
      responseData.props.fallback = {};
      console.log("Refresh token error in index getServerSideProps", error);
    }
  }
  return responseData;
}

export default function Web() {
  const isDesktop = useBreakpoint("bp3");

  if (isDesktop === undefined) {
    return <> </>;
  }

  if (isDesktop) {
    return (
      <>
        <main>
          <Layout desktop={true} blackBg={true} as='section'>
            <HeaderDesktopStickyWrapper>
              <HeaderDesktop textColor='white' showTicker />
            </HeaderDesktopStickyWrapper>

            <HomeWrapper />
          </Layout>

          <FooterDesktopWrapper />
        </main>
      </>
    );
  }

  return (
    <>
      <main>
        <ErrorAlert />
        <DropsTicker />
        <Layout
          blackBg={true}
          as='section'
          css={{
            padding: "$0",
          }}
        >
          <MobileNav />
          <HomeWrapper />
        </Layout>
        <FooterMobileWrapper />
      </main>
    </>
  );
}
