import { faqData } from "./faq-section-data";
import {
  details,
  detailsChevron,
  detailsDescription,
  detailsTitle,
  faqSectionWrapper,
} from "./faq-section.vanilla.css";

import { Autolayout, Text } from "@hoken/design-system";
import { Icons } from "@hoken/design-system";

export const FaqSection = (): JSX.Element => {
  return (
    <section className={faqSectionWrapper}>
      <Autolayout maxWidth='xxlarge' width='xxlarge' justify='center' paddingY='xxlarge'>
        <Text type='H1' as='h2' colors='white' alignment='center'>
          Frequently Asked Questions
        </Text>
        <Autolayout maxWidth='large'>
          {faqData.map((faq, index) => (
            <details key={index} className={details}>
              <summary>
                <Autolayout justify='spaceBetween' direction='row' align='center'>
                  <Text type='H3' colors='white' className={detailsTitle}>
                    {faq.summary}
                  </Text>
                  <Icons.ChevronDown color='white' className={detailsChevron} />
                </Autolayout>
              </summary>
              <Text
                type='P2Small'
                colors='white'
                className={detailsDescription}
                dangerouslySetInnerHTML={{ __html: faq.details }}
              />
            </details>
          ))}
        </Autolayout>
      </Autolayout>
    </section>
  );
};
