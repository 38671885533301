// Dependencies
import { FeaturedEventCardGrid, useBreakpoint } from "@hoken/core";

import { featuredEventsWrapper } from "./featured-events.vanilla.css";

// Styles
import { Autolayout, Text } from "@hoken/design-system";

export const FeaturedEvents = (): JSX.Element => {
  const isDesktop = useBreakpoint("isDesktop");
  return (
    <section className={featuredEventsWrapper}>
      <Autolayout
        maxWidth='xxlarge'
        paddingY={isDesktop ? "xxlarge" : "xlarge"}
        gap='xlarge'
      >
        <Autolayout align='center' width={isDesktop ? "xlarge" : "xxlarge"}>
          <Text type='H2' as='h2' colors='white' alignment='center'>
            Book your room for these in-demand events!
          </Text>
        </Autolayout>
        <Autolayout align='center' width={isDesktop ? "xlarge" : "xxlarge"} gap='xlarge'>
          <FeaturedEventCardGrid page='home' />
        </Autolayout>
      </Autolayout>
    </section>
  );
};
