export const faqData = [
  {
    summary: "Why book with Hoken?",
    details: `
      <p>We started Hoken because we knew there had to be a <b>better way to book hotels for</b> events. Traditional methods were frustrating—rooms were either too expensive, sold out, or in inconvenient locations with strict cancellation policies. We wanted to change that.</p> 
      <br/>
      <p><b>Hoken is your go-to marketplace for hassle-free event travel</b>, letting you focus on the fun, not the logistics. Here's why you'll love booking with us:</p> 
      <br/>
      <h4><strong>Secure the best rooms early</strong></h4>
      <p>Skip the endless searching. We lock in prime locations and great prices ahead of time, so you can score top rooms, even for popular events.</p> 
      <br/>
      <h4><strong>Unbeatable savings</strong></h4>
      <p>Enjoy competitive rates without the stress of constant comparing.</p> 
      <br/>
      <h4><strong>Maximum flexibility</strong></h4>
      <p>Life happens. Resell your reservation or get a full refund with our flexible cancellation policy.</p> 
      <br/>
      <p><b>Hoken saves you time and money</b> with a smarter way to book event hotels. We're your partner for creating <b>unforgettable event experiences.</b></p> 
    `,
  },
  {
    summary: "How does Hoken have such great room rates?",
    details:
      "Hoken secures rooms for popular events, months in advance. This allows us to offer you attractive pricing, potentially even lower than what you'd find through traditional booking methods.",
  },
  {
    summary: "Trust & Safety",
    details: `
      <h4><strong>Welcome to Hoken: Your Trusted Partner for Stress-Free Hotel Bookings</h4></strong>
      <br/>
      <p>We understand the importance of a secure and hassle-free hotel booking experience. Your trust is our top priority, and that's reflected in everything we do.</p>
      <br/>
      <p>Here's how we ensure your peace of mind throughout your booking journey:</p>
      <br/>
      <p>
        <ul style="list-style-type:disc;padding-left: 5%;">
          <li style="margin-bottom: 8px;"><strong>​Guaranteed Stays:​</strong> Partnering with top brands and highly-rated properties on TripAdvisor, we guarantee your hotel room. Every Hoken reservation is secured with a verified hotel confirmation number, ensuring your room is waiting for you upon arrival.</li>
          <li style="margin-bottom: 8px;"><strong>Unbeatable Savings:</strong> We secure rooms for popular events, months in advance. This allows us to offer you top-tier hotel rooms at incredible prices, often lower than traditional booking methods. Enjoy prime locations and amenities with complete transparency – no hidden fees.</li>
          <li style="margin-bottom: 8px;"><strong>Prepaid & Secure:</strong> Rest assured, your room is already paid for with the hotel. You'll only be charged for incidentals like additional services during your stay.</li>
          <li style="margin-bottom: 8px;"><strong>Unmatched Security:</strong> We prioritize the security of your information. Hoken utilizes industry-leading security practices, and we never store your sensitive financial details. <a style="color:var(--colors-greenTint)" href="https://stripe.com/">Stripe</a>, a trusted leader in secure payments, ensures your financial data remains completely protected.</li>
          <li style="margin-bottom: 8px;"><strong>Maximum Flexibility:</strong> Life happens. That's why most Hoken bookings are fully refundable. You can even opt to resell your room on our secure marketplace and potentially earn a profit.</li>
          <li style="margin-bottom: 8px;"><strong>Dedicated Customer Support:</strong> Our knowledgeable and experienced <a href="mailto:support@gohoken.com" style="color:var(--colors-greenTint)">customer support team</a> is available around the clock to answer your questions and address any concerns, big or small.</li>
        </ul>
      </p>
      <br/>
      <p>Book with confidence. Choose Hoken for your next event stay and let the fun begin!</p>
      <br/>
      <a href="https://hoken.zendesk.com/hc/en-us/articles/25358342725275-Trust-Safety" style="color:var(--colors-greenTint)">Read more about Trust & Safety.</a>
    `,
  },
  {
    summary: "How does Hoken ensure my room can be resold or fully refunded?",
    details:
      "Hoken secures hotel rooms well in advance to offer you competitive rates. To give you maximum flexibility, we initially manage these reservations. But you're always in control. Easily resell your reservation through our marketplace or get a full refund up until the deadline. The reservation will officially transfer to your name - or the final buyer's name - 2 days before check-in, ensuring a seamless hotel experience.",
  },
  {
    summary: "Will the hotel have a record of my reservation?",
    details: `<p>Yes, your reservation is securely recorded with the hotel. To provide you with flexibility, we transfer the reservation to your name 2 days before check-in. This allows you to get a refund or sell your room in the final days leading up to check-in.</p>
      <br/>
      <p>However, if you'd prefer it confirmed earlier for peace of mind, just <a style="color:var(--colors-greenTint)" href="mailto:support@gohoken.com">contact our customer support team.</a> We're happy to arrange it for you.</p>
      `,
  },
];
