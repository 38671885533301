import React, { useEffect, useRef } from "react";

import { useBreakpoint } from "@hoken/core";
import { isBrowser } from "@hoken/core/utilities/isBrowser";

import {
  TrustPilotWrapper,
  trustPilotClickTrap,
  trustPilotContainer,
} from "./trustpilot.vanilla.css";

type TrustpilotWidgetProps = {
  type: "horizontal" | "micro" | "mini" | "grid";
  theme: "light" | "dark";
  size?: "small" | "medium" | "large"; // Assuming these are your size options
};

const templateIds = {
  horizontal: "5406e65db0d04a09e042d5fc",
  micro: "5419b637fa0340045cd0c936",
  mini: "53aa8807dec7e10d38f59f32",
  grid: "539adbd6dec7e10e686debee",
};

export const TrustpilotWidget: React.FC<TrustpilotWidgetProps> = ({
  type,
  theme,
  size,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isDesktop = useBreakpoint("isDesktop");

  useEffect(() => {
    if (isBrowser() && window?.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  const style =
    type === "grid" ? { height: isDesktop ? "500px" : "1000px", width: "100%" } : {};
  const sizeProp = size || "medium";

  return (
    <div className={trustPilotContainer}>
      <div className={`${trustPilotClickTrap} tpl-control`} />
      <div
        ref={ref}
        className={`trustpilot-widget ${TrustPilotWrapper({
          size: sizeProp,
          type: type,
        })}`}
        data-locale='en-US'
        data-template-id={templateIds[type]}
        data-businessunit-id='63ab29ba0cd64197d7e1be2e'
        data-style-height={style.height}
        data-style-width='100%'
        data-theme={theme}
        style={style}
      >
        <a
          href='https://www.trustpilot.com/review/gohoken.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          Trustpilot
        </a>
      </div>
    </div>
  );
};
