import { varsHoken } from "@hoken/design-system";
import { style } from "@vanilla-extract/css";

export const faqSectionWrapper = style({
  backgroundColor: varsHoken.colors.black,
});

export const detailsChevron = style({
  transition: "transform 0.2s",
  selectors: {
    "details[open] &": {
      transform: "rotate(180deg)",
      fill: varsHoken.colors.green100,
    },
  },
});

export const details = style({
  borderBottom: `1px solid ${varsHoken.colors.gray750}`,
  paddingBottom: varsHoken.space.medium,
  cursor: "pointer",
});

export const detailsDescription = style({
  margin: `${varsHoken.space.medium} 0`,
});

export const detailsTitle = style({
  cursor: "pointer",
  width: "90%",
  paddingLeft: varsHoken.space.medium,
  ":before": {
    content: "",
    display: "inline-block",
    backgroundColor: varsHoken.colors.green100,
    width: "3px",
    height: varsHoken.space.medium,
    marginRight: `calc(${varsHoken.space.medium} - 3px)`,
    marginLeft: `calc(${varsHoken.space.medium} * -1)`,
  },
});
