// Interfaces
import { featuredOnLogos } from "./featured-on-logos.list";
// Styles
import {
  featuredOnLogo,
  featuredOnLogosContainer,
  featuredOnLogosWrapper,
  featuredOnWrapper,
} from "./featured-on.vanilla.css";

import { Text } from "@hoken/design-system";

export const FeaturedOn = () => {
  return (
    <section className={featuredOnWrapper}>
      <Text as='h2' type='H3' colors='white' alignment='center'>
        Explore{" "}
        <Text type='H3' as='span' colors='green100'>
          2,000+
        </Text>{" "}
        handpicked hotel rooms by leading brands.
      </Text>
      <div className={featuredOnLogosContainer}>
        <div className={featuredOnLogosWrapper}>
          {featuredOnLogos?.map(({ imgName, imgAlt }, index) => (
            <picture className={featuredOnLogo} key={index}>
              <img src={`/images/logos/${imgName}.svg`} alt={imgAlt} />
            </picture>
          ))}
        </div>
      </div>
    </section>
  );
};
