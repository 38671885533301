import { FeaturedOn } from "@hoken/core";

import { FaqSection } from "./faq-section";
import { FeaturedEvents } from "./featured-events/featured-events";
import { HowItWorks } from "./how-it-works/how-it-works";
import { WelcomeHero } from "./welcome-hero/welcome-hero";

export const HomeWrapper = (): JSX.Element => {
  return (
    <section>
      <WelcomeHero />
      <FeaturedEvents />
      <FeaturedOn />
      <HowItWorks />
      <FaqSection />
    </section>
  );
};
