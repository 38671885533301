export const howItWorksItems = [
  {
    icon: "HokenLocationsAndSavings",
    title: "Location & Savings, Simplified",
    desc: "<p>Skip the search, let our experts find your ideal hotel near the event. </p> <p>Last-minute booking? No problem! We secure the best available rates, every time.</p>",
    className: "vip-access",
  },
  {
    icon: "ChangeOfPlans",
    title: "Plans changed? No&nbsp;sweat!",
    desc: "<p>Life throws curveballs, but with Hoken, you have options. Resell your booking on our marketplace - you might even make a profit!</p><p> Or, get a full refund thanks to our flexible cancellation policy.</p>",
  },
  {
    icon: "HokenExpertTeam",
    title: "Expert team",
    desc: "<p>We started Hoken because we believe event travel shouldn't be complicated. Our team is here to answer your questions, help you find the perfect hotel, and offer seamless support throughout your trip.</p> <p>Focus on the fun, we've got the rest covered.</p>",
    className: "epic-savings",
  },
];
