import { formatDate, useBreakpoint } from "@hoken/core";
import { EventsCategoriesAsLinks } from "@hoken/core";

import { FeaturedEvents } from "../../event.interfaces";
import { useFeaturedEvents } from "../../hooks/useFeaturedEvents";
import { EventCard } from "../event-card";
import { EventContainer } from "../event-container.styles";
// Interfaces
import { FeaturedEventCardGridProps } from "./featured-event-card-grid.interfaces";
import { eventsList } from "./featured-event-card-grid.vanilla.css";

import { Autolayout, Text } from "@hoken/design-system";

export const FeaturedEventCardGrid = ({
  page,
  themeLight,
}: FeaturedEventCardGridProps) => {
  const { data, isLoading, error } = useFeaturedEvents();
  const isDesktop = useBreakpoint("bp3");

  if (error) {
    // TODO Report to sentry and implement events error UI
    return <></>;
  }

  if (isLoading) {
    const loadingCardsToDisplay = Array(6).fill(0);
    return (
      <div className={eventsList}>
        {loadingCardsToDisplay.map((_, index) => (
          <EventContainer loading key={index}>
            <div></div>
          </EventContainer>
        ))}
      </div>
    );
  }

  const events = data?.events as FeaturedEvents["events"];
  const eventCategories = data?.categories;
  const eventsToShow = isDesktop ? 6 : 6;
  const cappedEvents = page === "home" ? events?.slice(0, eventsToShow) : events;
  return (
    <>
      <div className={eventsList}>
        {cappedEvents?.map(
          ({
            id,
            name,
            start_at,
            end_at,
            venue,
            insights,
            pictures,
            logo,
            cities,
            promotions,
            event_group_id,
          }) => {
            const [eventImage] = pictures;
            const dateRange = formatDate({ startDate: start_at, endDate: end_at });
            const allCities = cities;
            const city = venue?.city;
            const state = venue?.state;
            const rooms = insights?.listings_count;
            const hotels = insights?.hotels_count;
            const showWaitingList = rooms === 0 || hotels === 0;
            const strikethroughPercentage = promotions?.strikethrough?.percentage;
            const strikethroughRoundedPercentage = strikethroughPercentage
              ? Math.round(strikethroughPercentage * 100)
              : undefined;
            const containerClassName = eventImage
              ? {
                  backgroundImage: `url(${eventImage})`,
                }
              : undefined;
            const encodedName = name
              ? encodeURIComponent(name.replace(/ /gi, "-").toLowerCase())
              : undefined;
            const encodedId = encodeURI(id);
            const encodedGroupId = event_group_id ? encodeURI(event_group_id) : undefined;

            const href =
              cities?.length > 0
                ? `tour/${encodedName}/${encodedGroupId}`
                : `events/${encodedName}/${encodedId}`;

            return (
              <EventCard
                key={id}
                name={name ?? ""}
                dateRange={dateRange}
                city={city ?? ""}
                hotel_room_count={rooms}
                hotel_count={hotels}
                showWaitList={showWaitingList}
                className={containerClassName}
                href={href}
                logo={logo}
                page={page}
                state={state ?? ""}
                eventImage={eventImage as string}
                cities={allCities}
                strikethroughPercentage={strikethroughRoundedPercentage}
              />
            );
          },
        )}
      </div>
      <Autolayout gap='small' align='center'>
        <Text colors={themeLight ? "primary" : "green100"}>
          Check all our events and categories:
        </Text>
        <EventsCategoriesAsLinks
          categories={eventCategories}
          selectedCategory={"none"}
          themeLight={themeLight}
        />
      </Autolayout>
    </>
  );
};
