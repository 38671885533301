import { varsHoken } from "@hoken/design-system";
import { style } from "@vanilla-extract/css";

export const eventsList = style({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: varsHoken.space.large,
  width: "100%",
  "@media": {
    "(min-width: 768px)": {
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: varsHoken.space.large,
    },
  },
});
