import { breakpoints } from "@hoken/design-system/themes";
import { style } from "@vanilla-extract/css";

export const searchWrapper = style({
  margin: "0 -.75rem",
  width: "calc(100% + 1.5rem)",
  "@media": {
    [breakpoints.sm]: {
      margin: "0",
      width: "100%",
    },
  },
});
