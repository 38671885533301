import type React from "react";

import { useBreakpoint } from "@hoken/core";

import { Autolayout, Text } from "@hoken/design-system";

interface FeatureHighlightCardProps {
  icon: React.ReactNode;
  title: string;
  type?: "small" | "medium";
  text?: string;
}
interface StyleProperties {
  direction: "row" | "column";
  fontStyle: "H3" | "H5Alt";
  width: "fitContent" | "full";
  margin: "auto" | "none";
  gap: "medium" | "small";
}

const setStyleProperties = (
  type: NonNullable<FeatureHighlightCardProps["type"]>,
  isMobile?: boolean,
): StyleProperties => {
  switch (type) {
    case "small":
      return {
        direction: isMobile ? "column" : "row",
        fontStyle: "H5Alt",
        width: "fitContent",
        margin: "none",
        gap: "small",
      };
    case "medium":
      return {
        direction: "column",
        fontStyle: "H3",
        width: "full",
        margin: "auto",
        gap: "medium",
      };
  }
};

export const FeatureHighlightCard = ({
  icon,
  title,
  text,
  type = "medium",
}: FeatureHighlightCardProps) => {
  const isMobile = useBreakpoint("isMobile");
  const { direction, fontStyle, width, margin, gap } = setStyleProperties(type, isMobile);
  return (
    <Autolayout width={width} margin={margin}>
      <Autolayout gap={gap} align='center' direction={direction}>
        {icon}
        <Text colors='white' type={fontStyle} alignment='center'>
          {title}
        </Text>
      </Autolayout>
      {text && (
        <Text colors='white' type='P2'>
          {text}
        </Text>
      )}
    </Autolayout>
  );
};
