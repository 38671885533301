import { Search, SearchAsButton, TrustpilotWidget, useBreakpoint } from "@hoken/core";

import { searchWrapper } from "./bottom-section.vanilla.css";

import { Icons } from "@hoken/design-system";
import { FeatureHighlightCard } from "@hoken/design-system";
import { Autolayout } from "@hoken/design-system";

interface Props {
  variant?: "a" | "b";
}
const ResponsiveTrustPilot = (): JSX.Element => {
  const isDesktop = useBreakpoint("isDesktop");
  return (
    <Autolayout marginTop='small' width='fitContent'>
      <TrustpilotWidget
        type='horizontal'
        theme='dark'
        size={isDesktop ? "small" : "medium"}
      />
    </Autolayout>
  );
};

export const BottomSection = ({ variant }: Props): JSX.Element => {
  const isDesktop = useBreakpoint("isDesktop");
  switch (variant) {
    case "b":
      return (
        <Autolayout
          direction={isDesktop ? "row" : "column"}
          justify='spaceBetween'
          align={isDesktop ? "start" : "center"}
          gap={isDesktop ? "xlarge" : "large"}
        >
          <Autolayout gap={isDesktop ? "medium" : "large"}>
            <Autolayout width='full' className={searchWrapper}>
              {isDesktop ? (
                <Search />
              ) : (
                <SearchAsButton placeholder='Search your event…' />
              )}
            </Autolayout>
            <Autolayout direction='row' justify='start' gap='large'>
              <FeatureHighlightCard
                icon={<Icons.HokenLocationsMini />}
                title='Prime Locations'
                type='small'
              />
              <FeatureHighlightCard
                icon={<Icons.HokenSavingsMini />}
                title='Epic Savings'
                type='small'
              />
              <FeatureHighlightCard
                icon={<Icons.HokenRefundsMini />}
                title='Easy Refunds'
                type='small'
              />
            </Autolayout>
          </Autolayout>
          <ResponsiveTrustPilot />
        </Autolayout>
      );
    default:
      return (
        <Autolayout
          direction={isDesktop ? "row" : "column"}
          justify='spaceBetween'
          gap='xxlarge'
        >
          <ResponsiveTrustPilot />
          <Autolayout direction='row' justify='spaceBetween'>
            <FeatureHighlightCard
              icon={<Icons.HokenLocations />}
              title='Prime Locations'
            />
            <FeatureHighlightCard icon={<Icons.HokenSavings />} title='Epic Savings' />
            <FeatureHighlightCard icon={<Icons.HokenRefunds />} title='Easy Refunds' />
          </Autolayout>
        </Autolayout>
      );
  }
};
