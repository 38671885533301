import { varsHoken } from "@hoken/design-system/themes";
import { breakpoints } from "@hoken/design-system/themes";
import { style } from "@vanilla-extract/css";
import { recipe } from "@vanilla-extract/recipes";

export const howItWorksWrapper = style({
  color: varsHoken.colors.white,
  padding: `${varsHoken.space["xxlarge"]} ${varsHoken.space["xlarge"]}`,
  margin: "0 auto",
  background: varsHoken.colors.gray900,
  "@media": {
    [breakpoints.sm]: {
      padding: varsHoken.space["xxlarge"],
    },
  },
});

export const howItWorksStepTitle = recipe({
  base: {
    color: varsHoken.colors.green100,
    fontSize: varsHoken.fonts.sizes[24],
    fontFamily: varsHoken.fonts.family.helvetica,
    marginBottom: "1rem",
    marginTop: "5rem",
  },
  variants: {},
});

export const howItWorksStepContent = recipe({
  base: {
    fontSize: varsHoken.fonts.sizes[18],
    fontFamily: varsHoken.fonts.family.franklin,
    marginBottom: "1rem",
  },
  variants: {},
});

export const howItWorksStepLink = recipe({
  base: {
    fontSize: varsHoken.fonts.sizes[18],
    color: varsHoken.colors.green100,
    fontFamily: varsHoken.fonts.family.franklin,
    textDecorationLine: "underline",
  },
  variants: {},
});

export const howItWorksDiv = style({
  gap: "1.5rem",
  display: "flex",
  marginTop: "1rem",
  flexDirection: "column",
  width: "100%",
  textAlign: "center",

  "@media": {
    "screen and (min-width: 768px)": {
      maxWidth: "350px",
    },
  },
});

export const howItWorksBenefitIconWrapper = style({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "6rem",
  margin: "auto",

  "@media": {
    "screen and (min-width: 768px)": {
      justifyContent: "center",
      width: "auto",
      margin: "unset",
    },
  },
});

export const howItWorksBenefits = style({
  display: "flex",
  width: "100%",
  gap: "2rem",
  marginTop: "1rem",
  marginBottom: "3rem",
  flexWrap: "wrap",
  justifyContent: "center",
  textAlign: "center",

  "@media": {
    "screen and (min-width: 768px)": {
      flexWrap: "nowrap",
      textAlign: "left",
    },
  },
});

export const howItWorksBenefitTitle = style({
  height: "auto",
  marginBottom: ".5rem",
  // fontSize: varsHoken.fonts.sizes[18],
  "@media": {
    [breakpoints.sm]: {
      height: "2.5rem",
      marginBottom: "2rem",
    },
  },
});

export const gradientTextWrapper = style({
  maxWidth: "800px",
  margin: "auto",
});

export const gradientText = style({
  background: "linear-gradient(90deg, rgb(91, 252, 184) 0%, rgb(133, 122, 255) 100%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  maxWidth: "800px",
  marginTop: "2rem",
  marginBottom: "2rem",
  textAlign: "center",
  fontSize: varsHoken.fonts.sizes[20],

  "@media": {
    "screen and (min-width: 768px)": {
      fontSize: varsHoken.fonts.sizes[40],
    },
  },
});

export const readyToRockButtonWrapper = style({
  maxWidth: "188px",
  margin: "0 auto",
  marginTop: "1.5rem",
});

export const howItWorksDesc = style({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
});
