import { useBreakpoint } from "@hoken/core";
import { CONTENT as HEROCONTENT } from "@hoken/core/components/hero/content";

import { BottomSection } from "./bottom-section";

import { Autolayout } from "@hoken/design-system";
import { Text } from "@hoken/design-system";

export const WelcomeHeroText = (): JSX.Element => {
  const isDesktop = useBreakpoint("isDesktop");
  const sectionWidth = isDesktop ? "xlarge" : "xxlarge";
  const sectionPadding = isDesktop ? "xxlarge" : "none";
  return (
    <Autolayout paddingY='xxlarge' maxWidth='xxlarge'>
      <Autolayout width={sectionWidth} paddingX={sectionPadding} gap='xxlarge'>
        <Autolayout maxWidth='medium' margin='none'>
          <Text type={isDesktop ? "H1" : "H2"} colors='white' as='h1'>
            {HEROCONTENT.title}
          </Text>

          <Text type={isDesktop ? "P1" : "P2"} colors='white'>
            {HEROCONTENT.subtitle}
          </Text>
        </Autolayout>
        <BottomSection variant='b' />
      </Autolayout>
    </Autolayout>
  );
};
